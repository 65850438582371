import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './home.jsx';
import ThankYouPage from './thankyoupage.jsx';
import Gallery from './gallery.jsx';
import BuildLog from './buildlog.jsx';
import HamsterCare from './hamstercare.jsx';
import About from './about.jsx';
import PageNotFound from './pagenotfound.jsx';
import Contact from './contact.jsx';
import Privacy from './privacy.jsx';
import {Helmet} from "react-helmet";

const Content = () => {
       
       return (
        <div className="content-container">
         
           <Helmet>
              <title>The Hammy Box</title>
              <meta name="description" content="Do It Yourself wooden Hamster Habitat. A cool guide to building a diy wooden hamster home. A great wood working project for someone looking to make their own customer hamster habitat. Come check out pictures of the build and read my detailed story on the Hammy Box.  This wooden box acts as a spaceous and secure habitat for your furry little friend, originally created from some store bought wood and scrap materials it is both safe and functional." />
           </Helmet>

           <Switch>   

            <Route path='/build' component={BuildLog} />      

            <Route exact path='/hamstercare' render={ (props) => <HamsterCare page={0} />} />
            <Route exact path='/hamstercare/diet' render={ (props) => <HamsterCare page={1} /> } />
            <Route exact path='/hamstercare/grooming' render={ (props) => <HamsterCare page={2} /> } />
                       
            <Route exact path='/about' component={About} />   
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/gallery' component={Gallery} />
            <Route exact path='/thankyou' component={ThankYouPage} />
            <Route exact path='/privacy' component={Privacy} />
            
            <Route exact path='/' component={Home} /> 

            <Route render={ (props) => <PageNotFound /> } />

           </Switch>
           <a href="/thankyou" className="hidden">ThankYou</a>
        </div>
        
    )
}

export default Content;
