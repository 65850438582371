import React, {useEffect} from 'react';
import SlideGallery from './slidegallery.jsx';
import EmailSignup from './emailsignup.jsx'

const Home = () => {

    useEffect( () => {

        fetch('https://valserv.herokuapp.com/wakeup/')
        .then(response => response.status)
        .catch( e => console.log('Unable to Ping Server:'));
    });

    return (
        <div className="home-content">

            <p className="quote-text">
            "Creativity is contagious, pass it on." - Albert Einstein ... maybe...
            </p>

            <SlideGallery />
            
            <p>
            This is the story of the Hammy Box. My attempt to build a unique and interesting habitat for my daughter’s pet hamster.  I do my best to explain why and how I designed the Hammy Box as well as a giving you complete rundown of the steps I took from beginning to end during the creation of said box.  My first attempt at this project was a success and I always regretted not taking photos or logs of the build along the way, so going at this a second time a few years later, I decided to record more of the process in hopes to share the results. Maybe someone out there would like to make their own Hammy Box, maybe you’re just looking for some inspiration and ideas, or maybe you’re just looking for a good story. In every case, I hope not to disappoint. I'm also giving out a free copy of the google sketchup plan I made for this build, it's a simple design but may help someone out there.
            </p><br/>            
          
            <iframe title="yt-video" width="560" height="315" src="https://www.youtube.com/embed/lRtnGaORuNE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <EmailSignup />

            <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=15&p=48&l=ur1&category=amazonhomepage&f=ifr&linkID=b8178265550f6b52c0baa669f4802a97&t=hammybox-20&tracking_id=hammybox-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" styles="border:none;" frameborder="0"></iframe>

        </div>
    )
}

export default Home;