import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

const BuildLogPageFive = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
    };

    const [scrollToTopOfPage, elRef] = useScroll();

    return (
        <div className="build-log-page">

        <h1 ref={elRef}>BUILD LOG</h1>

        <ul className="buildlog-quick-links">
                <li>
                    <Link to='/build/pageone'>
                        Ideas / Planning
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagetwo'>
                        Tools / Materials
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li className="selected">
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
            </ul>

        <div className="putting-together-content">

            <h3>Finishing Touches</h3>

            <p>
            As far as things have come, I still feel like there is so much work to do. The little details on a project can really take up so much time.  I finally have this completed box structure with a top and functional front door but the second floor still needs accessibility, so that would be the next task. For this part of the build I really encourage you to use your imagination and customize your own interior but remember to provide a decent amount of airflow between floors.
            </p>

            <div className="finish-imgs">
                <a target="_blank" href="./build/inside1.jpg"><img alt="inside view" src="./build/inside1.jpg"></img></a>
                <a target="_blank" href="./build/gluing-floor3.jpg"><img alt="glueing the ramp to the floor" src="./build/gluing-floor3.jpg"></img></a>
            </div>

            <span className="image-caption">Finding a place for and affixing the hamster tubes was easy, but gluing the ramp in place was a challenge.</span>
      
            <p>
            I wanted the hamster to have a few different options to make his or her way between floors. I decided on a large ramp and 3 different hamster tube locations. I cut a couple of holes in the back corners of the second floor for hamster tubes. I then made a third large hole in the center of the floor where I would place a ramp. The second floor panel now had three holes in it, but it was sort of ugly. 
            </p>

            <div className="finish-imgs">
                <a target="_blank" href="./build/inside2.jpg"><img loading="lazy" alt="wood edges around holes" src="./build/inside2.jpg"></img></a>
                <a target="_blank" href="./build/inside3.jpg"><img loading="lazy" alt="wood ramp and edges" src="./build/inside3.jpg"></img></a>
            </div>

            <span className="image-caption">Here you can see the nice chewable edging around each entrance on the second floor.</span>
      
            <p>
            I created nice wood edges with scrap poplar and used them to frame each hole. This added a decorative touch and hid the wood/plexiglass edge of the floor panel. I made the edges nice and thick so they could function as a chewable surface as well. 
            </p>

            <p>
            I tried to create some variety and obstacles in the hamster habitat. I held the wooden pieces in place using a combination of glue and short screws from underneath the floor. I also attached the main ramp directly to the second floor panel.  
            </p>

            <div className="finish-imgs">
                <a target="_blank" href="./build/inside5.jpg"><img loading="lazy" alt="inside view" src="./build/inside5.jpg"></img></a>
            </div>

            <span className="image-caption">The bottom floor with some tubing attached and a platform with ramp in place.</span>

            <p>
            On the bottom floor, I built a small wooden platform that would allow a hamster a private hiding place underneath it. The platform was also covered in Plexiglas and I made a small ramp leading up to the top of it. A second ramp led from the platform to the top floor via the large center hole. Next to this entrance was also a hamster tube that would curve off into a different direction on the way down.  I secured a hamster tube in the back left corner going from the bottom floor all the way to the second level. The third hamster tube was placed on top of the small platform and came out on the second floor in the back right corner. I added a wooden block ramp on the second floor leading up the hamster tube in the back left corner. I now had an interior with lots of space and lots of variety.
            </p>
        
            <div className="finish-imgs">
                <a target="_blank" href="./build/inside6.jpg"><img loading="lazy" alt="interior details" src="./build/inside6.jpg"></img></a>
                <a target="_blank" href="./build/inside4.jpg"><img loading="lazy" alt="interior details" src="./build/inside4.jpg"></img></a>
            </div>

            <span className="image-caption">The interior all ready for some bedding and a happy hamster.</span>
      
            <p>
            I gave all interior and exterior exposed edges and wood surfaces a good sanding, this way there were no sharp edges for a hamster to hurt themselves on. I used my router to round off the edges along the top of the box. Once the hamster box was nice and smooth, I cleaned it thoroughly with compressed air to get all the saw dust out, followed by a damp rag.
            </p>

            <div className="finish-imgs">
                <a target="_blank" href="./build/finished1.jpg"><img loading="lazy" alt="completed and sanded" src="./build/finished1.jpg"></img></a>
                <a target="_blank" href="./build/finished2.jpg"><img loading="lazy" alt="completed and sanded" src="./build/finished2.jpg"></img></a>
            </div>

            <span className="image-caption">A freshly sanded Hammy Box, still needs some cleanup.</span>
      
            <p>
            I hung a water bottle from the back top edge on the inside using a couple of screws and the mounting mechanism that came with it, use your imagination here. There are lots of options as far as where to place the water, I wanted easy access from the top, this way it can be removed and refilled without disturbing the box inhabitants. </p> 
    
            <div className="finish-imgs">
                <a target="_blank" href="./build/finished4.jpg"><img loading="lazy" alt="all cleaned up and ready" src="./build/finished4.jpg"></img></a>
                <a target="_blank" href="./build/finished3.jpg"><img loading="lazy" alt="front door open" src="./build/finished3.jpg"></img></a>
            </div>
            
            <span className="image-caption">A view of the end product, ready for a new tenant.</span>
      
            <p>
            This build allows you to place a large hamster wheel, suitable for a Syrian hamster, in a number of places on either floor.  One further option which I did on the first build, and will likely add to this one, is the addition of a long hamster tube running along the ceiling of the box between the corner tubes in the back, this adds a sort of 3rd level and yet more variety, use your imagination. Once filled with bedding, some toys and some food, this Hammy Box will be all ready to go.  
            </p>

        </div>

        <div className="page-end-btn-container">
                
                <div id="page-end-prev">
                    <Link to='/build/pagefour'>
                        {window.innerWidth >= 1024 ? "Previous - Putting It All Together" : "Previous"}
                    </Link>
                </div>
                <div id="page-end-top" onClick={ () => scrollToTopOfPage() }>
                   Top of Page
                </div>
                <div>
                </div>

            </div>  

    </div>
    )
}

export default BuildLogPageFive;