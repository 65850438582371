import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

const BuildLogPageOne = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    return (
        <div className="build-log-page">

            <h1 ref={elRef}>THE BUILD</h1>

            <ul className="buildlog-quick-links">
                <li className="selected">
                    <Link to='/build/pageone'>
                        Ideas / Planning
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagetwo'>
                        Tools / Materials
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
            </ul>

            <h3>Ideas and Planning</h3>

            <div className="ideas-planning-content">
                <p>
                Throughout the next few pages I will attempt to give you a good overview of the steps I took in the making of the Hammy Box. This guide will serve as a general design and assembly outline, you may customize this in your own way all you like.  A general knowledge of wood working and its related tools is required and assumed. A good knowledge of safety is also important, you should always make sure to use eye and ear protection when appropriate.
                </p>

                <img alt="CAD Components" src="parts-exploded.jpg"></img>

                <p>
                So I had been tasked with creating a hamster cage.  I had never made a hamster cage before, I had some ideas but soon realized I had a lot to learn. I researched materials, I needed something strong, relatively light and safe for the hamster. Wood seemed to be a good choice, but some woods are harmful to hamsters.  In the end, I chose Poplar because it's a nice hardwood that is very chew safe for hamsters, it's also relatively light and inexpensive. 
                </p>

                <img alt="CAD Model of Hamster Box" src="./build/cad-box.jpg"></img>

                <p>
                I just happened to be lucky enough to have some scrap panes of Plexiglas lying around, it would make great window material. I opened up my google sketch up and started modelling.  This cage would need to fit in my daughter’s room, I also wanted something that was easy for her to handle for cleaning and playtime. I decided on a simple rectangular box shape. The box would be 2’ wide, 16 1/2” deep and almost 2’ high. I could place a second floor across the mid point of the box effectively doubling the overall habitat area. 
                </p>

                <p>
                I wanted the box to be easy to open and clean, but it had to be secure too. The top lid would hinge at the back so it could be opened up all the way for top access and the front panel would be fully removable.  The front panel was composed of two independent sections, an upper and a lower panel that would be attached with hinges in the middle. This would allow you to access the top floor without disturbing the bottom. Each panel on the box would have two window panes inset within it that would be fitted with Plexiglas making the box interior viewable from all angles. I would also cover each floor with a layer of Plexiglas, easy on hamster feet and easy to wipe down when needed. The front panel would be held on with a series of magnets, a wooden guide, and a single safety hook on the side for backup. 
                </p>

                <img alt="CAD Model of Hamster Box" src="./build/cad-box-open.jpg"></img>

                <p>
                So there it was, my idea for a hamster home in digital form. A simple yet effective design, safe and durable and very accessible for a child. There is a second floor, but no access between it and the ground floor. I decided to work on the details later and just worry about the general box for now. I was quite happy with my design, offering the hamster plenty of space, it seemed like a pretty straight forward build as well.  Once the box was finished, I could add small details, toys, ramps, tubes, etc. Building this cage certainly wasn’t going to be cheaper than buying one but I was sure it would be nicer and more functional. Now that I had my design, it was time to start building!
                </p>

            </div>

            <div className="page-end-btn-container">
               
               <div></div>
               <div id="page-end-top" onClick={ () => scrollToTopOfPage() }>
                   Top of Page
               </div>
               <div id="page-end-next">
                   <Link to='/build/pagetwo'>  
                    {window.innerWidth >= 1024 ? "Next - Tools and Materials" : "Next"}
                   </Link>
               </div>

            </div>            


        </div>
    )
}

export default BuildLogPageOne;