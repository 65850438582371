import React from 'react';
import './App.scss';
import Navbar from './components/nav.jsx';
import Content from './components/content.jsx';
import Footer from './components/footer.jsx';

function App() {

  return (
    <div className="app-container">
        <Navbar />
        <Content />
        <Footer />
    </div>
  );
}

export default App;
