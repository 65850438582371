import React from 'react';

const PageNotFound = () => {

    return (
        <div>
            <h1>404 Page Not Found</h1>
            <p>
                Sorry, the page you are looking for doesn't appear to be here. Try something else!
            </p>
        </div>
    )
}

export default PageNotFound;