import React, { useEffect, useRef } from 'react';
import {useRouteMatch, Route, Switch, Redirect} from 'react-router-dom';
import BuildLogPageOne from './buildlog/pageone.jsx';
import BuildLogPageTwo from './buildlog/pagetwo.jsx';
import BuildLogPageThree from './buildlog/pagethree.jsx';
import BuildLogPageFour from './buildlog/pagefour.jsx';
import BuildLogPageFive from './buildlog/pagefive.jsx';

const BuildLog = () => {

     let match = useRouteMatch();
     
     const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

      const [executeScroll, elRef] = useScroll();

    useEffect(() => {
        
        let e = document.getElementsByClassName('build-log')[0];  
        if ( e !== undefined && e !== null )
                e.className = "build-log build-log-open";

        executeScroll();
       });

    return (
        <div ref={elRef} className="build-log-container">          

                <div className="build-log">
                                    
                  <Switch>
      
                    <Route path={match.path + "/pageone"} component={BuildLogPageOne}/>
                    <Route path={match.path + "/pagetwo"} component={BuildLogPageTwo}/>
                    <Route path={match.path + "/pagethree"} component={BuildLogPageThree}/>
                    <Route path={match.path + "/pagefour"} component={BuildLogPageFour}/>
                    <Route path={match.path + "/pagefive"} component={BuildLogPageFive}/>
                    <Redirect from='/build' to='/build/pageone' />
    
                  </Switch>                 

                </div>
        </div>
    )
}

export default BuildLog;

