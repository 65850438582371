import React, {useEffect, useRef} from 'react';

const About = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    useEffect( () => {
        scrollToTopOfPage();
    });

    return (
        <div className="about-container">

            <div className="about-content">
                <h2 ref={elRef}>What is a Hammy Box?</h2>
                <p>
                My name is Matt, and I am father to two girls, 13 and 8 who absolutely adore Hamsters.  A few years ago, my oldest daughter, Petra requested a Hamster as her own little pet, promising to take the best care of it. True to her word, Petra diligently researched Hamster ownership and care. We thought this would be a good learning opportunity for her as well so we decided we would adopt a new furry baby...you see, we already had a cat, but the cat… she’s MY baby.  We would wait until Petra's birthday to adopt, so we still had some time to prepare.      
                </p>

                <div className="gizzy-pic-container">
                    <img id="about-gizzy-img" alt="Gizmo the Cat" src="./gizzy.jpg"></img>
                    <p>Who doesn't like a good cat picture? This is Gizmo, resting after a hard day of play.</p>
                </div>
                
                <p>
                As a father with a few hobbies, I didn’t want to miss the opportunity to build something cool for my kids, so my mind started going. I got to work on some sketches and quick CAD model then put my modest wood working skills to use.  Using some scrap materials as well as wood purchased from the store I managed to put together a pretty cool home - dubbed "The Hammy Box" for my daughter’s first hamster. I waited until her Birthday that year to unveil her surprise, she was soooo happy, this meant she was finally going to get her hamster.
                </p>

                <img id="about-hamster-img" alt="Happy Hamster" src="./first-hammybox.jpg"></img>
            
                <p>
                After years of happy Hamster ownership, my youngest daughter caught the hamster bug and was now begging for her own furry little friend.  It wasn’t a tough decision, she had some experience with her older sister’s hamster and seemed to handle her well.  With only a month and a half before Christmas, I was now pondering whether or not I had time to delve into another project.  Thankfully, I still had my original google sketch-up plans to go from, so I got to work quickly and managed to complete a second hamster box with time to spare.  This past Christmas 2020 I gave her the new hamster home, she was so excited. Unfortunately, since everything had been locked down with Covid-19 she hasn't yet had the chance to make her adoption.    
                </p>

                <img id="about-hamster-img" alt="Happy Hamster" src="./happyhammy.jpg"></img>
            
                <p>
                Since having to do this project again, I decided to make a build log with pictures, detailing how I made the box, hoping to share it with others. Perhaps someone else out there would like to make something like it or just looking for inspiration…. in any case, this is the story of the Hammy Box.
                </p>
 
            <div className="top-of-page-btn" onClick={ () => scrollToTopOfPage() }>Top of Page</div>
          
            </div>  
        </div>
    )
}

export default About;