import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

const BuildLogPageTwo = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
       
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    return (
        <div className="build-log-page">

            <h1 ref={elRef}>BUILD LOG</h1>

            <ul className="buildlog-quick-links">
                <li>
                    <Link to='/build/pageone'>
                        Ideas / Planning
                    </Link>
                </li>
                <li className="selected">
                    <Link to='/build/pagetwo'>
                        Tools / Materials
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
            </ul>

            <div className="tools-materials-content">
                
                <h3>Tools and Materials</h3>

                <p>
                You will need a decent assortment of tools to do this sort of build easily, listed below is what I personally used during the construction of the Hammy Box. You may use whichever tools do the job for you. I have also listed all of the materials that I used to complete this build, they are all readily available at your local hardware + building center.
                </p>

                <div className="tools-materials-table">

                    <div className="tools-table-list">
                    <h4>Tools</h4>
                        <ul>
                            <li>
                                Table Saw
                            </li>
                            <li>
                                Drill
                            </li>
                            <li>
                                Router
                            </li>
                            <li>
                                 Jig Saw   
                            </li>
                            <li>
                                Chisel
                            </li>
                            <li>
                                Screw Driver
                            </li>
                            <li>
                                Bar Clamps
                            </li>
                            <li>
                                Various Small Clamps
                            </li>
                            <li>
                                 Tape Measure   
                            </li>
                            <li>
                                Square
                            </li>
                            <li>
                                Pencil
                            </li>
                        </ul>
                    </div>

                    <div className="materials-table-list">
                    
                        <h4>Materials</h4>
                        
                        <ul>
                            <li>
                                2 x Poplar Board - Measuring 7 1/4" Wide x 3/4" Thick x 8' Long 
                            </li>
                            <li>
                                1 x Poplar Board - Measuring 4 1/2" Wide x 3/4" Thick x 4' Long
                            </li>
                            <li>
                                1/4" Birch Panel Board - Approx. 4'sq Peice should be more than enough
                            </li>
                            <li>
                                PlexiGlas Panels - A couple of 4x4 Sheets, 1/8" thick should do the job
                            </li>
                            <li>
                                Non-Toxic Wood Glue / Silicone
                            </li>
                            <li>
                                Sand Paper
                            </li>
                            <li>
                                A Variery of Wood Screws - 1" - 1 1/2" Long
                            </li>
                            <li>
                                Plastic Hamster Tubes
                            </li>
                            <li>
                                3 - 5 x Strong Magnets
                            </li>
                            <li>
                                A Small Hook Latch
                            </li>
                        </ul>

                    </div>

                </div>

            </div>

            <div className="page-end-btn-container">
               
               <div id="page-end-prev">
                   <Link to='/build/pageone'>
                        {window.innerWidth >= 1024 ? "Previous - Ideas and Planning" : "Previous"}
                    </Link>
               </div>

               <div id="page-end-top" onClick={ () => scrollToTopOfPage() }>
                   Top of Page
               </div>
               <div id="page-end-next"><Link to="/build/pagethree">
                {window.innerWidth >= 1024 ? "Next - Getting To Work" : "Next"}
                </Link>
               </div>

            </div>            


        </div>
    )
}

export default BuildLogPageTwo;