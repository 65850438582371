import React from 'react';
import {Link} from 'react-router-dom';

const MobileFooter = () => {
    return (
        <div>
        <div className="site-map-mobile">

            <ul>
                <h5>THE BUILD</h5>
                <li>
                    <Link to='/build/pageone'>
                        Ideas and Planning
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagetwo'>
                        Tools and Materials
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
                <h5>HAMSTERS</h5>
                <li>
                    <Link to='/hamstercare'>
                        Habitat and Environment
                    </Link>
                </li>
                <li>
                    <Link to='/hamstercare/diet'>
                        Diet and Nutrition
                    </Link>
                </li>
                <li>
                    <Link to='/hamstercare/grooming'>
                        Grooming and Cleaning
                    </Link>
                </li>
            </ul>

            <ul className="footer-ul-right">
                <li>
                    <Link to='/'>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to='/about'>
                        About
                    </Link>
                </li>
                <li>
                    <Link to='/contact'>
                        Contact
                    </Link>
                </li>
                <li>
                    <Link to='/privacy'>
                        Privacy
                    </Link>
                </li>
                <li>
                    <Link to='/gallery'>
                        Photo Gallery
                    </Link>
                </li>
            </ul> 
        </div>
    </div>

    )
}

export default MobileFooter;