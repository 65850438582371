import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

const BuildLogPageFour = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    return (
        <div className="build-log-page">

        <h1 ref={elRef}>BUILD LOG</h1>

        <ul className="buildlog-quick-links">
                <li>
                    <Link to='/build/pageone'>
                        Ideas / Planning
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagetwo'>
                        Tools / Materials
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li className="selected">
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
            </ul>

        <div className="putting-together-content">

            <h3>Putting It All Together</h3>
        
            <p>
            Now that I had all of my side panels made, I could finally start assembling the box structure.  I built a base for the box structure using 4 of the pieces I had cut earlier, two 20 ½” x 2” pieces butted up against two 15” x 1” pieces and glued to form a flat square frame. This square frame acted as a base from which I could start attaching the side panels.
            </p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/base-frame.jpg"><img alt="base frame" src="./build/base-frame.jpg"></img></a>
            </div>

            <span className="image-caption">This is the base frame that I started my assembly from.</span>

            <p>
            After applying glue to the outer edges of the base frame, I used 1 1/2” screws to secure the two side panels and then the rear panel to the base frame to form most of the box. I screwed the base frame into the side and rear panels from the inside so they would not be visible.
            </p>

            <div className="asm-imgs">

                <a target="_blank" href="./build/base-screws.jpg"><img alt="screws in base" src="./build/base-screws.jpg"></img></a>
                <a target="_blank" href="./build/assembly1.jpg"><img alt="starting assembly" src="./build/assembly1.jpg"></img></a>
            
            </div>

            <span className="image-caption">These screws along with a good glob of wood glue connected the base to the first side panel.</span>
            
            <div className="asm-imgs">
                
                <a target="_blank" href="./build/assembly2.jpg"><img loading="lazy" alt="box assembly" src="./build/assembly2.jpg"></img></a>
                <a target="_blank" href="./build/assembly3.jpg"><img loading="lazy" alt="box assembly" src="./build/assembly3.jpg"></img></a>      

            </div>

            <span className="image-caption">Then the second side panel...</span>

            <p>
            The rear panel was made to overhang the base frame on each side by 3/4", allowing it to sit flush with each side panel's outter face. I put a number of 1 1/2" screws through the rear panel into the side panels on each side along the vertical edge.  The rear panel and two side panels were now solidly attached to the base frame and each other.</p> 
            
            <div className="build-note">
                <h5>BUILD NOTE:</h5>
                <p>It's important to pre-drill the holes for your screws along the panel edge to avoid cracking.
                </p>
            </div>

            <p>
            To add rigidity to the structure, I added support rails across the top front and side edges of the box using the two 14 1/4" x 1" peices for the sides and the 22 1/2" x 1 1/2" peice across the front.  Since the front panels move independently and are removable they aren’t part of the structure and so they don’t really offer any strength or support, so these additional rails were important. Secondly, this gave me an edge to attach a latching mechanism, in this case, a large magnet.
            </p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/assembly4.jpg"><img loading="lazy" alt="box assembly" src="./build/assembly4.jpg"></img></a>          
                <a target="_blank" href="./build/top-edge.jpg"><img loading="lazy" alt="top edge frame and magnet" src="./build/top-edge.jpg"></img></a>  
            </div>

            <span className="image-caption">The magnet on the top rail is used to keep the top door panel closed.</span>

            <p>
            I chiseled out a space for the main magnet at the top, I kept it in place using glue.
            </p>
            
            <p>
            Next I cut two pieces of birch panel wood, and two pieces of Plexiglas. One pair for each floor of the Hammy Box, to be glued together into a single floor panel. 
            </p>

            <ul>
                <li>
                2 X - 22 1/2" x 15" - Birch Panel 1/4" Thickness
                </li><br/>
                <li>
                2 X - 22 1/2" x 15" - Plexiglas Pane 1/8" Thickness
                </li>
            </ul>

            <div className="asm-imgs">
                <a target="_blank" href="./build/gluing-floor.jpg"><img loading="lazy" alt="glueing floor panels" src="./build/gluing-floor.jpg"></img></a>
                <a target="_blank" href="./build/gluing-floor2.jpg"><img loading="lazy" alt="glueing floor panels" src="./build/gluing-floor2.jpg"></img></a> 
            </div>

            <span className="image-caption">Getting my work out and glueing some wood at the same time, these weights really help hold the peice down.</span>


            <p>
            I laid the wood panel down first on the bottom of the box, this would be the base of the floor, and glued it securely with a couple of screws. Next, I used silicone to glue and seal the Plexiglas panel to the wood panel base. This gave the box a surface that was quite hamster safe yet easy to cleanup. 
            </p>

            <div className="asm-imgs">         
                <a target="_blank" href="./build/top-view.jpg"><img loading="lazy" alt="top view" src="./build/top-view.jpg"></img></a>
                <a target="_blank" href="./build/floor-edges.jpg"><img loading="lazy" alt="floor edging" src="./build/floor-edges.jpg"></img></a> 
            </div>

            <span className="image-caption">The whole second floor panel slides in and out of the box on these ledges, this will be handy for maintenance.</span>

            <p>
            In order to create a second floor in the Hammy Box, I added wooden edges around the mid-point of the box using a few of the peices I had cut in the beginning. I used glue and 1 1/4" screws to attach the 21" x 1" length and two 15" x 1" lengths around the back and sides of the box. This made a ledge where I could then slide the second floor panels in and out of the box, making the second floor removable. I glued the wood and Plexiglas panels together to make the second floor a single peice, but it wasn’t done yet, hammy still needs a way to get from downstairs to upstairs and back again. 
            </p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/front-view.jpg"><img loading="lazy" alt="front view top floor" src="./build/front-view.jpg"></img></a>
            </div>

            <span className="image-caption">The second floor panel is one solid piece made by glueing a wood panel and plexiglass together.</span>
      
            <p>
            I attached the top of the Hammy Box to the box structure with a couple of nice solid hinges. I also assembled the two front panel frames together with a smaller set of hinges so that the top frame opens outwards.
            </p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/top-hinges.jpg"><img loading="lazy" alt="lid hindges in place" src="./build/top-hinges.jpg"></img></a>  
                <a target="_blank" href="./build/front-hinges.jpg"><img loading="lazy" alt="front door hinges" src="./build/front-hinges.jpg"></img></a>  
            </div>

            <span className="image-caption">Putting hinges on the top lid and front door panels. The bronze seemed to go well with the wood color.</span>      

            <p> 
            I decided to try something interesting for a latching mechanism on the Hammy Box.  I scavenged some magnets from some old hard drives, and stuck them to the bottom two corners of the lower front panel door then I put matching magnets on the outside edge of the box frame. I found that this securely held the front panel on as it was, but I added a 22 ½” x 1” wooden guide, made from extra wood, along the bottom edge of the front panel to force vertical and horizontal alignment with the box.</p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/front-guide.jpg"><img loading="lazy" alt="door guide" src="./build/front-guide.jpg"></img></a>  
            </div>

            <span className="image-caption">Here you can see the wood guide along the bottom half of the front door panel. Below that, on each side are matching magnets to the ones on the box.</span>
      
            <p>
             I added a single hook latch along the side as a backup or safety. When I built the first Hammy Box, I had added magnets to all four corners of the lower front panel, I found that this was overkill if the magnets were strong enough, two would work just fine.
            </p>

            <div className="asm-imgs">
                <a target="_blank" href="./build/old-hdds.jpg"><img loading="lazy" alt="old hard drves" src="./build/old-hdds.jpg"></img></a>  
                <a target="_blank" href="./build/magnets.jpg"><img loading="lazy" alt="scavanged magnets" src="./build/magnets.jpg"></img></a>  
            </div>

            <span className="image-caption">Pulled apart a bunch of old hard drives for some pretty strong magnets, these would be perfect.</span>
      
            <p>
            The top door panel was held up by one strong magnet in the center of the top of the box, while the bottom door panel was held on by the lower magnets. This allowed someone to easily pull open the top door for access to the inside of the box, while still keeping the lower second of the box securely closed. There are many options, you can use your imagination and come up with your own latching system, this is just what I did. 
            </p>
            
            <div className="asm-imgs">
                <a target="_blank" href="./build/plastic-pipes.jpg"><img loading="lazy" alt="hamster tubes" src="./build/plastic-pipes.jpg"></img></a>
            </div>

            <p>
            Now with the main box fully assembled, it was time to start making this box look more like a hamster home.
            </p>

        </div>


        <div className="page-end-btn-container">
                
                <div id="page-end-prev">
                    <Link to='/build/pagethree'>
                        {window.innerWidth >= 1024 ? "Previous - Getting To Work" : "Previous"}
                    </Link>
                </div>
                <div id="page-end-top" onClick={ () => scrollToTopOfPage() }>
                   Top of Page
                </div>
                <div id="page-end-next">
                    <Link to='/build/pagefive'>
                        {window.innerWidth >= 1024 ? "Next - Finishing Touches" : "Next"}
                    </Link>
                </div>

            </div>  

    </div>
    )
}

export default BuildLogPageFour;