import React, {useState, useEffect} from 'react';

const SlideGallery = () => {

    const [currSlide, setSlide] = useState(0);

    const slides = [

        "/slides/slide1.jpg",
        "/slides/slide2.jpg",
        "/slides/slide3.jpg",
        "/slides/slide4.jpg",
        "/slides/slide5.jpg"
    ];

    useEffect( () => {

        setTimeout( () => {

            let e = document.getElementById('slide-frame');
            if ( e !== undefined && e !== null )
                e.className = "slide-fadeout";

            setTimeout( () => {
                
                if ( currSlide < slides.length - 1 )
                    setSlide(currSlide + 1);
                else
                    setSlide(0);

                let e = document.getElementById('slide-frame');
                if (e !== undefined && e !== null )
                    e.className = "slide-fadein";

            }, 1000);

        }, 5000);
    });

    return (
            <div id="slide-frame">

                <a href="/gallery"><img alt="Slide" src={slides[currSlide]} /></a>
                
            </div>
    )
}

export default SlideGallery;