import React, {useEffect, useState, useRef} from 'react';

const Contact = () => {

    const [token, setToken] = useState('');
    const [msgText, setMsgText] = useState('Type your message here...');
    const serverUrl = 'https://valserv.herokuapp.com';
    const [formCompleted, setFormCompleted] = useState(false);

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    const handleSubmitContactForm = () => {        
      
        let firstName = document.getElementById('ctf-first-name').value;
        let lastName = document.getElementById('ctf-last-name').value;
        let emailAddr = document.getElementById('ctf-email-addr').value;
        let subject = document.getElementById('ctf-subject').value;    
        let csrfToken = document.getElementById('csrf-id').value;    

        if ( msgText.length < 10 ) {
            alert('Please make your message longer...');
            return;
        }

        fetch(serverUrl+'/hambox/contactform', {       
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({          
                user: {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddr: emailAddr,
                    subject: subject,
                    message: msgText                                     
                },
                csrf: {
                    token: token, 
                    formId: csrfToken   
                }
            })
        }).then(response => {
            
                if ( response.status === 200 )      {              // contact message was sent
                
                    let e = document.getElementsByClassName('contact-form-container')[0];
                    if ( e !== undefined && e !== null )
                        e.remove();

                    setToken('');
                    setFormCompleted(true);
                }
                
            })
            .catch( e => console.log('Contact Message Not Sent'));
    }

    useEffect( () => {

        // request token on page render

        if ( token.length === 0 ) {

            fetch(serverUrl+'/hambox/contacttoken')
            .then(response => {
                return response.json()
                .then(data => {
                    setToken(data.token);
                })
            }).catch( () => setToken(''));
            
        }

        scrollToTopOfPage();
    });
    
    return (
        <div ref={elRef} className="contact-form-content">
    
            <div className="contact-form-container">
                
                <h2>Leave A Message...</h2>        

                <form method="POST" onSubmit={ (e) => { handleSubmitContactForm(); e.preventDefault() } }>

                    <div className="contact-form-item">
                        <label>First Name</label>
                        <input id="ctf-first-name" type="text" minLength="2" maxLength="128" required />
                    </div>

                    <div className="contact-form-item">
                        <label>Last Name</label>
                        <input id="ctf-last-name" type="text" minLength="2" maxLength="128" required />
                    </div>

                    <div className="contact-form-item">
                        <label>Email Address</label>
                        <input id="ctf-email-addr" type="email"  minLength="4" maxLength="128" required />
                    </div>

                    <div className="contact-form-item">
                        <label>Subject</label>
                        <input id="ctf-subject" type="text"  minLength="2" maxLength="128" required/>
                    </div>

                    <div className="contact-form-item">
                        <label>Message</label>
                        <textarea id="ctf-messsage" maxLength="280" onChange={ (e) => setMsgText(e.target.value)} value={msgText}></textarea>
                    </div>
                    <br/>
                    <input type="submit" id="send-msg-btn" value="Send Message"></input>

                    <div className="hidden" aria-hidden="true">
                        <input id="csrf-id" type="text" name="_charset_" tabindex="-1" defaultValue="hb-contact-form" />
                    </div>

                </form>
            </div>
            
            {formCompleted ? (
            <div className="contact-success-msg">
                <p>Thank you! Your contact form was successfully sent.</p>
                <a href="/" >HOME</a>
            </div>) : null }
            
        </div>
    )
}

export default Contact;