import React, {useEffect, useRef} from 'react';
import { matchPath } from 'react-router-dom';

const HamsterCare = ({page}) => {

     const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();
    const [scrollToHabitat, habRef] = useScroll();
    const [scrollToDiet, dietRef] = useScroll();
    const [scrollToGrooming, grmRef] = useScroll();

    if ( matchPath === '/hamstercare/diet')
      page = 1;
    else if ( matchPath === '/hamstercare/grooming')
      page = 2;

    useEffect(() => {
    
        if ( page === 0 )
            scrollToTopOfPage();
        else if ( page === 1 )
            scrollToDiet();
        else if ( page === 2 )
            scrollToGrooming();

       });

    return (
        <div className="hamstercare-container">

            <div ref={elRef} className="hamstercare-content">

                <h2>HAMSTER CARE</h2>

                <ul className="hamstercare-quick-links">
                    <li>
                        <a onClick={ () => { scrollToHabitat(); } }>Habitat &#x000B7; Environment</a>
                    </li>
                    <li>
                        <a onClick={ () => { scrollToDiet(); } }>Diet &#x000B7; Nutrition</a>
                    </li>
                    <li>
                        <a onClick={ () => { scrollToGrooming(); } }>Grooming &#x000B7; Cleaning</a>
                    </li>
                </ul>

                <div className="hamstercare-disc">
                    <p>
                    *Disclaimer – I am NOT a veterinarian nor am I any sort of authority on Hamster health. I will do my best to pass on quality information, that I have learned through my own research and years of experience owning hamsters, in order to help others. Below is a compilation general tips on owning and caring for your small furry pet.  However this list is not necessarily exhaustive and my knowledge and experience is limited to Syrian hamsters. I suggest for any real health related questions or problems, always contact your vet. 
                    </p>
                </div>                
                
                <h3 ref={habRef}>Habitat / Environment</h3>

                <img id="hammy-img" alt="A Hamster" src="./hammy.jpg"></img>

                <ul className="hab-env-ul">
                    <li>
                    Hamsters require a dry, clean and quiet place to be comfortable. 
                    </li>
                    <li>
                    Hamsters require easy access to food and water.
                    </li>
                    <li>
                    Hamsters prefer an environmental temperature between 68deg F and 72deg F (20-22deg Celsius).
                    </li>
                    <li>
                    Hamsters need a place they can burrow and hide themselves from the outside.
                    </li>
                    <li>
                    A hamster should not be exposed to dampness or made wet, avoid any cool drafts.
                    </li>
                    <li>
                    A hamster’s environment should be relatively quiet with a stable light and dark cycle.
                    </li>
                    <li>
                    Hamster’s have very good hearing and therefore have sensitivities to some of our everyday human gadgets. Keep the hamster away from noisy appliances and anything that will emit a high frequency such as some electronics.
                    </li>
                    <li>
                    Hamsters have very sensitive noses as well, so keep them in an environment with mild, neutral smells, nothing overly strong should be used around their cage to avoid overloading their senses.    
                    </li>
                    <li>
                    Hamsters require nesting material from which to build their nest and burrow through. Fluffy or stringy bedding material can be dangerous to your hamster and should NEVER be used, opt to use paper or safe wood products. Cedar wood shavings should NOT be used. 
                    </li>
                    <li>
                    Hamsters require lots of room to run around, places to hide and toys to play with to make their environment interesting and allow them exercise.  
                    </li>
                    <li>
                    Pet hamsters should always be housed alone to prevent territorial aggression.
                    </li>
                    <li>
                    Wire mesh cage bottoms and wheels can hurt hamster feet, opt for smooth, comfortable materials.
                    </li>
                    <li>
                    Hamsters are easily stressed, when getting to know a new pet, always be gentle, quiet and slow.  Don’t handle a new hamster too much in the first week, allow time for adjustment to his or her new home.
                    </li>
                </ul>

                <hr />

                <h3 ref={dietRef} id="dietnut-title">Diet / Nutrition</h3>

                <img id="hammy-donut" alt="A Hamster in a Donut" src="./hammy-donut.jpg"></img>

                <ul className="hab-env-ul">
                    <li>
                    A Hamster requires regular access to water and food.   
                    </li>
                    <li>
                    A hamster can only live 3-4 days without food, less for water.    
                    </li>
                    <li>
                    Ideally your hamster’s food should consist of 15-24% protein and 3-6% fat, these numbers increase for pregnant and baby hamsters.    
                    </li>
                    <li>
                    Hamsters require a diet high in carbohydrates due to their energy expenditure.
                    </li>
                    <li>
                    Hamsters can be fed a nutritionally complete diet with pellets alone, however they benefit from a variety of nuts, fruits and vegetables added to their diet.
                    </li>
                    <li>
                    Pellets are generally preferred to seed/food mixes to avoid selective eating and nutritional imbalance.
                    </li>
                    <li>
                    Hamsters enjoy fruits such as apples, blueberries, grapes, banana and strawberries. Don’t overdo the sweet fruits, too much sugar can be bad for your hamster.
                    </li>
                    <li>
                    Vegetables such as broccoli, spinach, cucumber, carrots, cauliflower and sweet bell peppers add lots of nutrition to your hamster’s meals.
                    </li>
                    <li>
                    You should NEVER feed your hamster junk food, no apple seeds or almonds either. Avoid anything with a spice on it, avoid hot peppers, garlic, onions and all citrus. 
                    </li>
                    <li>
                    Hamsters can, and will eat their own poop, and that’s OK.  It’s totally normal and nutritionally beneficial for a hamster to re-digest their food.
                    </li>
                </ul>

                <div className="hamster-foods-table">

                    <div className="good-foods-list">
                        <h4><span id="safe-text">SAFE</span> for Hammy</h4>

                        <ul>
                            <li>
                                Apples
                            </li>
                            <li>
                                Cherries
                            </li>
                            <li>
                                Strawberries
                            </li>
                            <li>
                                Pears
                            </li>
                            <li>
                                Blueberries
                            </li>
                            <li>
                                Grapes
                            </li>
                            <li>
                                Peaches
                            </li>
                            <li>
                                Bananas
                            </li>
                            <li>
                                Raspberries / Blackberries
                            </li>
                            <li>
                                Cranberries
                            </li>
                            <li>
                                Raisins
                            </li>
                            <li>
                                Melon / Watermelon
                            </li>
                            <li>
                                Mango / Cantaloupe
                            </li>
                            <li>
                                Lychee Fruit
                            </li>
                            <li>
                                Kiwi (no skin)
                            </li>
                            <li>
                                Broccoli
                            </li>
                            <li>
                                Spinach
                            </li>
                            <li>
                                Kale
                            </li>
                            <li>
                                Cucumber
                            </li>
                            <li>
                                Sweet Bell Pepper
                            </li>
                            <li>
                                Carrots
                            </li>
                            <li>
                                Cauliflower
                            </li>
                            <li>
                                Celery
                            </li>
                            <li>
                                Green Beans
                            </li>
                            <li>
                                Romaine Lettuce
                            </li>
                            <li>
                                Asparagus
                            </li>
                            <li>
                                Turnip
                            </li>
                            <li>
                                Corn / Popcorn (plain)
                            </li>
                            <li>
                                Parsley
                            </li>
                            <li>
                                Bok Choy
                            </li>
                            <li>
                                Swiss Chard
                            </li>
                            <li>
                                Dandelion Greens
                            </li>
                            <li>
                                Cooked Potatoes
                            </li>
                            <li>
                                Peanuts
                            </li>
                            <li>
                                Walnuts
                            </li>
                            <li>
                                Pumpkin Seeds
                            </li>
                            <li>
                                Sunflower Seeds
                            </li>
                            <li>
                                Pistachios
                            </li>
                            <li>
                                Pecans
                            </li>
                            <li>
                                Cashews
                            </li>
                            <li>
                                Hazelnuts
                            </li>
                            <li>
                                Pine Nuts
                            </li>
                        </ul>
                    </div>

                    <div className="bad-foods-list">
                        <h4><span id="bad-text">BAD</span> for Hammy</h4>

                        <ul>
                            <li>
                                Oranges
                            </li>
                            <li>
                                Tangerines
                            </li>
                            <li>
                                Clementines
                            </li>
                            <li>
                                Lemons
                            </li>
                            <li>
                                Limes
                            </li>
                            <li>
                                Grapefruit
                            </li>
                            <li>
                                Pineapple
                            </li>
                            <li>
                                Tomatoes
                            </li>
                            <li>
                                Iceberg Lettuce
                            </li>
                            <li>
                                Eggplant
                            </li>
                            <li>
                                Raw Potatoes
                            </li>
                            <li>
                                Avocado
                            </li>
                            <li>
                                Onions
                            </li>
                            <li>
                                Garlic
                            </li>
                            <li>
                                Mushrooms
                            </li>
                            <li>
                                Rhubarb
                            </li>
                            <li>
                                Chocolate
                            </li>
                            <li>
                                Grape / Apple / Pear Seeds
                            </li>
                            <li>
                                Peach / Cherry Pits
                            </li>
                            <li>
                                Almonds
                            </li>
                        </ul>

                    </div>
                </div>

                <hr />

                <h3 ref={grmRef} id="grooming-title">Hamster Grooming</h3>

                <img id="sleepy-ham-img" alt="A Sleepy Hamster" src="./sleepy-ham.jpg"></img>

                <ul className="hab-env-ul">
                    <li>
                    When it comes to grooming or cleaning your Hamster, there isn’t much to do as they are very self-cleaning.  Hamsters will continuously clean themselves so there is no need for you to do it.
                    </li>
                    <li>
                    Never ever bath your hamster or get them wet. It can make them very sick and is not needed as they are fully able to clean themselves. 
                    </li>
                    <li>
                    Many hamsters enjoy being pet or scratched with a small brush.
                    </li>
                    <li>
                    Hamsters need plenty of chewable surfaces to gnaw on and keep their teeth healthy.   
                    </li>
                    <li>
                    Hamsters that exhibit over-grooming may be stressed, this can be a sign that something’s wrong.
                    </li>
                  </ul>

                  
                    <h3>Habitat Cleanup</h3>
                  <ul className="hab-env-ul">
                    <li>
                    As self-cleaning as the hamster may be, we still need to make sure their habitat is cleaned regularly and carefully. 
                    </li>
                    <li>
                    Regular spot cleaning of the habitat should be done to avoid urine and poop buildup.
                    </li>
                    <li>
                    More thorough cleaning of the hamster habitat should be done every couple of weeks, give or take depending on size. Over-cleaning is stressful to the hamster.
                    </li>
                    <li>
                    Vinegar and warm water make a great cleaning solution for your hamster cage that is safe and effective.
                    </li>
                    <li>
                    When cleaning the cage, effort should be made to not disturb your hamster’s nest area or food hoards. 
                    </li>
                    <li>
                    Remove any old or rotting food from food hoards, replace with fresh food.
                    </li>
                    <li>
                    During a thorough cleaning, save the clean bedding so that it can be mixed with fresh bedding and put back into the habitat. This ensures the hamster’s smell remains on her home territory and reduces stress from the cleaning. 
                    </li>

                </ul> 
          
            
                <div className="top-of-page-btn" onClick={ () => scrollToTopOfPage() }>Top of Page</div>
            
            </div>
          
        </div>
    )
}

export default HamsterCare;