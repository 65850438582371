import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';

const BuildLogPageThree = () => {

    const [currImage, setImage] = useState(0);

    const imageList = [
        "./build/box-side-specs.jpg",
        "./build/rear-panel-specs.jpg",
        "./build/front-panel-specs.jpg", 
        "./build/toplid-specs.jpg"
    ];

    useEffect(() => {

        let e = document.getElementsByClassName('spec-image-selected');
        for ( let i = 0; i < e.length; i++ ) 
                e[i].className = "spec-img-btn";
        
        e = document.getElementsByClassName('spec-img-btn');
        
        for ( let i = 0; i < e.length; i++ ) {
            
            if ( currImage === i )
                e[i].className = 'spec-image-selected';
        }
    });

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth'});     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    return (
        <div className="build-log-page">

            <h1 ref={elRef}>BUILD LOG</h1>

            <ul className="buildlog-quick-links">
                <li>
                    <Link to='/build/pageone'>
                        Ideas / Planning
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagetwo'>
                        Tools / Materials
                    </Link>
                </li>
                <li className="selected">
                    <Link to='/build/pagethree'>
                        Getting to Work
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefour'>
                        Putting It All Together
                    </Link>
                </li>
                <li>
                    <Link to='/build/pagefive'>
                        Finishing Touches
                    </Link>
                </li>
            </ul>

            <div className="getting-to-work-content">

                <h3>Getting to Work</h3>
            
                <p>
                I went to my local big box lumber store and picked up the nicest and straightest poplar boards I could find, believe me when I say this is no easy task. Two eight footers that were 7 ¼” wide x ¾” thick and an extra 4 ½” x 4’ board gave me enough for the box as well as a few extra toys and features.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/poplarboards.jpg"><img id="wood-image-pad" alt="Poplar Boards" src="./build/poplarboards.jpg"></img></a>
                    <a target="_blank" href="./build/poplarboards2.jpg"><img alt="Poplar Boards" src="./build/poplarboards2.jpg"></img></a>
                </div>
                <span className="image-caption">Here are the two 8 footers, I picked up the 4' peice later, AFTER I realized I needed it.</span>

                <p>
                The first thing I did was break down the sketch up model into “puzzle pieces”.  I basically needed a bunch of stiles and rails to frame the box. I would start by building the sides, then I would connect all of the sides to form the box. The box will be composed of two sides, a front and back, and a top. A number of pieces will also be needed for the top + base frame as well as the edges for the second floor panel.
                </p>

                <img id="cad-box-specs-img" alt="Hammy Box CAD" src="./build/cad-box-specs.jpg"></img>         
                <p>I basically need to make this.</p>
                <hr width="80%" />
                <p>Below is a list of all of the wood lengths that need to be cut for the Hammy Box build as well as the Panel configurations. Wood lengths not used in the side panels will be used later in the project.</p>       

                <div className="woodrails-image-content">

                    <div className="wood-rail-list">

                        <h4>Wood Lengths Required</h4>

                        <ul>
                            <li>
                            22” x 2” x 3/4” - QTY x 7
                            </li>
                            <li>
                            22” x 1 1/2” x 3/4” - QTY x 6
                            </li>
                            <li>
                            22 1/2” x 1 1/2” x 3/4" - QTY x 1
                            </li>
                            <li>
                            21” x 1” x 3/4” - QTY x 1
                            </li>
                            <li>
                            20 1/2” x 2” x 3/4” - QTY x 2
                            </li>
                            <li>
                            16 1/2” x 2” x 3/4” - QTY x 2
                            </li>
                            <li>
                            15” x 1” x 3/4” - QTY x 4 
                            </li>
                            <li>
                            14 1/2” x 2” x 3/4” - QTY x 1
                            </li>
                            <li>
                            14 1/4” x 1” x 3/4 - QTY x 2
                            </li>
                            <li>
                            13” x 2” x 3/4” - QTY x 4    
                            </li>
                            <li>
                            13” x 1 1/2” x 3/4” - QTY x 2    
                            </li>
                            <li>
                            11 1/2" x 2" x 3/4" - QTY x 2
                            </li>
                            <li>
                            10 1/2" x 2" x 3/4" - QTY x 2
                            </li>
                        </ul>

                    </div>

                    <div className="spec-images">
                        <h5>Box Panels</h5>
                        <div className="spec-image-btns">
                            <ul>
                                <li id="side-image-btn" className="spec-img-btn" onClick={ ()=> setImage(0) } >
                                    Side
                                </li>
                                <li id="rear-image-btn" className="spec-img-btn" onClick={ ()=> setImage(1) } >
                                    Rear
                                </li>
                                <li id="front-image-btn" className="spec-img-btn" onClick={ ()=> setImage(2) } >
                                    Front
                                </li>
                                <li id="top-image-btn" className="spec-img-btn" onClick={ ()=> setImage(3) } >
                                    Top
                                </li>
                                    
                            </ul>
                        </div>  

                         <img alt="Panel Measurements" src={imageList[currImage]}></img>
                                  
                    </div>

                </div>

                <br/>

                <div className="wood-cut-imgs">

                <a target="_blank" href="./build/cut_wood1.jpg"><img loading="lazy" alt="Cutting The Wood" src="./build/cut_wood1.jpg"></img></a>
                <a target="_blank" href="./build/cut_wood2.jpg"><img loading="lazy" alt="Cutting Wood Peices" src="./build/cut_wood2.jpg"></img></a>
           
                </div>
                
                <span className="image-caption">These are the orignal boards cut up into lengths first.</span>

                <p>
                I took the 8’ boards of poplar and cut them up as efficiently as possible into lengths approximate to my needs. Next I then cut each of the boards length-wise into the various widths required. Eventually, pulling myself out from underneath the saw dust, I emerged with a stack of precisely cut rectangles of wood. Woohoo! I was off to a good start.
                </p>
                   
                <div className="wood-cut-imgs">
                <a target="_blank" href="./build/cut_wood4.jpg"><img loading="lazy" alt="Completed Wood Lenghts" src="./build/cut_wood4.jpg"></img></a>
                <a target="_blank" href="./build/cut_wood3.jpg"><img loading="lazy" alt="Completed Wood Rails" src="./build/cut_wood3.jpg"></img></a>
                </div>

                <span className="image-caption">Once cut lengthwise into the exact widths needed, the pile looked like this.</span>

                <hr width="80%" />

                <p>
                Once I had all of my pieces, I started arranging them and looking for the best fit for each piece. I grouped set of rails together as a set for each of the sides and labelled them. For the joints I intended to use rabbets at the corners and dado grooves for the middle rail, this would be the next step.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/dado-cut.jpg"><img loading="lazy" alt="dado groove" src="./build/dado-cut.jpg"></img></a>
                    <a target="_blank" href="./build/rabbet-cut.jpg"><img loading="lazy" alt="a rabbet joint" src="./build/rabbet-cut.jpg"></img></a>
                </div>
                
                <span className="image-caption">Left: Groove for center rail. / Right: Top and Bottom Rails.</span>
                
                <p>
                I carefully measured and cut all of my rabbet joints first, making sure each of my pieces fit as I progressed. It’s easier to setup a machine like a table saw once and then run a bunch of pieces with the same cut through it. This ensures uniformity in the cuts, and in the end means squarer pieces.  I used my table saw to create the rabbets by setting the blade height and continuously running the piece back and forth over the blade using a custom made crosscut sled. This allows me to make pretty accurate joints. I used the same technique to make the dado grooves, I cut close to the final width and then fine tune it to make sure I get a tight fit on all my pieces. I smooth out any rough spots with a chisel.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/making-grooves.jpg"><img loading="lazy" alt="gooved wood peices" src="./build/making-grooves.jpg"></img></a>
                </div>

                <span className="image-caption">These are the wood components of one side panel, all the joints cut.</span>

                <p>
                Once all of the rabbets and dados are cut, it’s time to do a dry assembly of the panels. I fit the pieces together to ensure uniformity and square. Everything looks good, all of my panels fit together nicely, they should glue up well but before I can do that, there is still one important task. I needed to cut grooves into the inside of each side rail to allow me to insert window panels.
                </p>

                <div className="dry-fit-imgs">
                    <a target="_blank" href="./build/dry-fit1.jpg"><img loading="lazy" alt="dry fitting panels" src="./build/dry-fit1.jpg"></img></a>
                    <a target="_blank" href="./build/dry-fit2.jpg"><img loading="lazy" alt="dry fitting panels 2" src="./build/dry-fit2.jpg"></img></a>
                    <a target="_blank" href="./build/dry-fit3.jpg"><img loading="lazy" alt="dry fitting panels 3" src="./build/dry-fit3.jpg"></img></a>
                </div>

                <span className="image-caption">Dry fitting the wooden components of the side and rear panels.</span>                

                  <p>
                I had decided to use some old scrap Plexiglas I had leftover, this saved me quite a bit of money as this stuff isn’t cheap to buy new. It was old and pretty dirty, some places a little scratched up but I was sure I could clean it up and make it look nice. I’m sure there are a number of materials that might work in its place but this was available, it was free, it was strong, it was safe, and I could work with it. 
                </p>

                <p>
                I would cut out panels of Plexiglas to make windows for each of the sides of the box. The panels would slide into grooves cut into the edges of the side rails, once the box sides were assembled, the windows would be in place and secure.  
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/plexiglas1.jpg"><img loading="lazy" alt="plexiglas panels" src="./build/plexiglas1.jpg"></img></a>
                    <a target="_blank" href="./build/plexiglas2.jpg"><img loading="lazy" alt="plexiglas panel cuts" src="./build/plexiglas2.jpg"></img></a>
                </div>

                <span className="image-caption">Cutting the plexisglas into rectangles.</span>

                <p>
                The Plexiglas sheets I had were rather large to start with, about 4’x4’ square.  I started by cutting out sections, trying to find the best parts.  I then cut pieces to size for each window in each panel, giving myself an extra ~1/4” on each side so that the window would sink into the grooved edge.
                </p>

                <p>
                All four sides as well as the top lid of the Hammy Box have two windows, making ten windows required in total. I needed to cut the following peices of Plexiglas to make them all.
                </p>

                <ul className="window-panes-list">
                    <li>
                        2 X - 8 1/2" x 12 1/2" - Side Windows (Top Pane)
                    </li>
                    <li>
                        2 X - 9" x 12 1/2" - Side Windows (Bottom Pane)
                    </li>
                    <li>
                        3 X - 8 1/2" x 20 1/2" - Rear Panel (Top + Bottom Pane) / Front Panel (Bottom Pane)
                    </li>
                    <li>
                        1 X - 7 1/2" x 20 1/2" - Front Panel (Top Pane)
                    </li>
                    <li>
                        2 X - 13" x 9 1/2" - Top / Lid Panes
                    </li>                    
                </ul>

                <p>
                Once I had all of my windows cut to size I could continue with cutting the grooves into the wood panels. I carefully setup the table saw with a ¼” depth on the blade. Setting my fence so that I could put each peice on it's side and cut just off center of the inside edge of each wooden rail. Once I had the right spot, double checked with a scrap piece of wood, I started running all of my rails through creating a ¼” deep length-wise groove into which the window panel would fit. Now the width of the Plexiglas was just larger than the width of my table saw blade, so I would need to make two cuts to make a proper fit. I ran each piece through to make the initial groove first, then I proceeded to adjust for the remaining width needed. This allowed me to maintain a uniform groove in each of my pieces. Once the adjustment was made, I ran each piece through a second cut after which I had a nice precise fit. My rails and windows were now all cut and ready to be assembled.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/window-grooves1.jpg"><img loading="lazy" alt="window grooves" src="./build/window-groove1.jpg"></img></a>
                    <a target="_blank" href="./build/window-grooves2.jpg"><img loading="lazy" alt="window grooves 2" src="./build/window-groove2.jpg"></img></a>
                </div>

                <span className="image-caption">This groove allows me to sink the plexiglas into the wooden frame about 1/4" deep all the way around.</span>

                <br/>
                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/finished-rails1.jpg"><img loading="lazy" alt="wood rails completed" src="./build/finished-rails1.jpg"></img></a>
                    <a target="_blank" href="./build/finished-rails2.jpg"><img loading="lazy" alt="completed wood peices" src="./build/finished-rails2.jpg"></img></a>
                </div>

                <span className="image-caption">Stacks of completed wood rails all ready to be assembled into panels.</span>

                <p>
                The next challenge was to fit together all of the pieces and glue them so that they stay nice and flat and square. I started with the sides of the box, which were the smaller panels to make.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/dry-fit4.jpg"><img loading="lazy" alt="dry fit panel" src="./build/dry-fit4.jpg"></img></a>
                </div>

                <span className="image-caption">Woohoo it fits!</span>
                
                <p>
                I fit the middle rail and stiles together first, then I could slide in the top and bottom window before adding the top and bottom rails.  All of my joints generously glued, checking and rechecking to make sure I kept everything square, I clamped them down securely and waited.
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/clamped2.jpg"><img loading="lazy" alt="clamping and glueing" src="./build/clamped2.jpg"></img></a>
                    <a target="_blank" href="./build/clamped1.jpg"><img loading="lazy" alt="clamping and glueing" src="./build/clamped1.jpg"></img></a>
                </div>
                
                <span className="image-caption">I use an old door as a work surface because its really flat and has a nice varnished top. Great for clamping peices to. It's handy for projects like this.</span>

                <p>
                After what felt like forever, I released the clamps and took a look at my panels. They looked pretty good, and they were square. I continued this process for the rear and top panels of the box, these panels were a little bigger, but didn’t offer any surprises so everything went together well. 
                </p>

                <br/>
                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/clamped3.jpg"><img loading="lazy" alt="clamping and glueing" src="./build/clamped3.jpg"></img></a>
                    <a target="_blank" href="./build/clamped4.jpg"><img loading="lazy" alt="clamping and glueing" src="./build/clamped4.jpg"></img></a>
                </div>

                <span className="image-caption">Wood working has given me a new appreciation of clamps and how few I actually own. I have a decent assortment, built up over the years, but you can never truely have enough.</span>

                <p>
                Lastly, I put together the front panel frames in the same way. These were just single pane frames that would be attached with a couple of hinges, so they were pretty simple as far as assembly.
                </p>

                <p>
                The last step in completing all of my side panels was to drill an appropriate number of air holes. Since the walls are made of plastic, it’s important to get as much air flow as possible so drilling air holes is essential to a good hamster home. I drilled plenty of holes in all of the side’s windows with the exception of the front door panels. 
                </p>

                <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/finished-side.jpg"><img loading="lazy" alt="complete panel" src="./build/finished-side.jpg"></img></a>
                </div>
                
                <span className="image-caption">Yay, a completed side panel! Managed to keep it nice and square, just needs a little sanding is all.</span>

                <p>
                Drilling the Plexiglas is something that in hindsight I probably should have done before assembling the panels, unfortunately I managed to put a small crack in the rear panel drilling one of the holes when the drill bit grabbed a cloth backing I was using. Thankfully it looks worse than it actually is and didn't affect the build in any way. On top of that, it just happened to be in a spot that would be covered by an interior feature. 
                </p>
               
               <div className="wood-cut-imgs">
                    <a target="_blank" href="./build/air-holes2.jpg"><img loading="lazy" alt="making air holes" src="./build/air-holes2.jpg"></img></a>
               </div>

                <span className="image-caption">Managed to put a small crack in the plexiglas, didn't do much harm thankfully, but lesson learned.</span>

                <p>
                The holes should be as large as possible without allowing the hamster to chew easily. Put as many holes in the sides as you can, I would aim for at least 200+ holes around the box. It's tedious work but necessary. I also find that the small gap between the front door panels due to the hinges, allows some extra air flow.
                </p>

                <div className="wood-cut-imgs">
                     <a target="_blank" href="./build/air-holes1.jpg"><img loading="lazy" alt="making air holes" src="./build/air-holes1.jpg"></img></a>
                     <a target="_blank" href="./build/air-holes3.jpg"><img loading="lazy" alt="making air holes" src="./build/air-holes3.jpg"></img></a>
                </div>

                <p>
                I now had four sides and a top to my Hammy Box, everything was looking pretty good but there was still a lot of work to do. I still needed a base, edging, floors and detailing inside need to be created, but the next step would be to start actually assembling the box from my newly created side panels.
                </p>

            </div>
            <div className="page-end-btn-container">
                
                <div id="page-end-prev">
                    <Link to='/build/pagetwo'>
                        {window.innerWidth >= 1024 ? "Previous - Tools and Materials" : "Previous"}
                    </Link>
                </div>
                <div id="page-end-top" onClick={ () => scrollToTopOfPage() }>
                   Top of Page
                </div>
                <div id="page-end-next">
                    <Link to='/build/pagefour'>
                        {window.innerWidth >= 1024 ? "Next - Putting It All Together" : "Next"}
                    </Link>
                </div>

            </div>            

        </div>
    )
}

export default BuildLogPageThree;