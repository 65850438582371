import React from 'react';
import {Link} from 'react-router-dom';

const Navbar = () => {

    const toggleMenuItem = (menuId, showMenu) => {

        if ( window.innerWidth <= 1024 )        // if this is a mobile device, disable slide out menu subitems
            return;

        let e = document.getElementById(menuId);

        if ( e === null || e === undefined )
            return;

        if ( showMenu )
            e.className = "submenu submenu-slideout";
        else
            e.className = "submenu submenu-slidein";
    }

    return (
        <div className="nav-container">
            <div className="nav">
                <img className="nav-header" alt="hammy box header" src="./thb_header.jpg"></img>
                <ul>
                    <li>
                        <Link to="/build/pageone" 
                           onMouseOver={ () => toggleMenuItem("submenu-build", true) } 
                           onMouseLeave={ () => toggleMenuItem("submenu-build", false) }>
                            <h2>THE BUILD</h2>
                            <div id="submenu-build" className="submenu">

                                <ul>
                                    <li>
                                        <Link to='/build/pageone'>Ideas and Planning</Link>
                                    </li>
                                    <li>
                                        <Link to='/build/pagetwo'>Tools and Materials</Link>
                                    </li>
                                    <li>
                                        <Link to='/build/pagethree'>Getting to Work</Link>
                                    </li>
                                    <li>
                                        <Link to='/build/pagefour'>Putting It All Together</Link>
                                    </li>
                                    <li>
                                        <Link to='/build/pagefive'>Finishing Touches</Link>
                                    </li>
                                </ul>
                                
                            </div>
                            </Link>
                    </li>
                    {window.innerWidth >= 1024 ? <span>&#x000B7;</span> : null}
                    <li>
                        <Link to="/hamstercare"
                           onMouseOver={ () => toggleMenuItem("submenu-care", true) } 
                           onMouseLeave={ () => toggleMenuItem("submenu-care", false) } >
                            <h2>HAMSTERS</h2>
                            <div id="submenu-care" className="submenu">

                                <ul>
                                    <li>
                                        <Link to='/hamstercare'>
                                            Habitat / Environment
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/hamstercare/diet'>
                                            Diet / Nutrition
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/hamstercare/grooming'>
                                            Grooming / Cleaning
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/gallery'>
                                            Photo Gallery
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </Link>
                    </li>
                    {window.innerWidth >= 1024 ? <span>&#x000B7;</span> : null}
                    <li>
                        <Link to="/about"
                            onMouseOver={ () => toggleMenuItem("submenu-about", true) } 
                            onMouseLeave={ () => toggleMenuItem("submenu-about", false) } >
                            <h2>ABOUT</h2>
                            <div id="submenu-about" className="submenu">

                                <ul>
                                    <li>
                                        <Link to='/about'>
                                            What's A Hammy Box?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/contact'>
                                            Contact Form
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/privacy'>
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </Link>
                    </li>
                    {window.innerWidth >= 1024 ? <span>&#x000B7;</span> : null}
                    <li>
                        <Link to="/">
                            <h2>HOME</h2>
                        </Link>
                    </li>
                </ul>

            </div>

        </div>
    )
}

export default Navbar;