import React from 'react';

const EmailSignup = () => {

    let isValidEmail = (email) =>         
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return (true)
        }
            return (false)
    }
     
    return (
            <div>
                <div className="download-label">Get Your Free Project Plans!</div>

                    <div className="download-container">

                        <p>Sign up for updates, notifications and promotions and get a FREE copy of the CAD plans for The Hammy Box.</p>

                        <div id="mc_embed_signup">
            
                            <form action="https://hammybox.us1.list-manage.com/subscribe/post?u=14852de9c6f90e46e11977002&amp;id=b0f2adec49" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
            
                            <div id="mc_embed_signup_scroll">
                                <input type="email" defaultValue="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required />  

                                <div className="hidden" aria-hidden="true">
                                    <input type="text" name="b_14852de9c6f90e46e11977002_b0f2adec49" tabindex="-1" defaultValue="" />
                                </div>
                                
                                <div class="clear">
                                    <input type="submit" value="Subscribe" name="subscribe" id="sub-btn" class="button" onClick={ () => { let e = document.getElementById('mce-EMAIL'); if ( isValidEmail(e.value)) localStorage.setItem('email', e.value); } }/>
                                </div>

                            </div>
                        </form>  
                        </div>

                    </div>
            </div>
    )
}

export default EmailSignup;