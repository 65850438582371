import React, {useEffect, useState} from 'react';

const ThankYouPage = () => {

    const [token, setToken] = useState('');
    const serverUrl = 'https://valserv.herokuapp.com';

    const initDownload = () => {        // initialize download with valid JWT token

        window.location = serverUrl+'/hambox/download/?token='+token;
    }

    const handleDownload = () => {

        let e = document.getElementsByClassName('download-btn')[0];
        if ( e !== undefined && e !== null )
            e.remove();             // remove the download button

        initDownload();         // start the download 
    }

    useEffect( ()=> {
        
        if ( token.length === 0 ) {

            fetch(serverUrl+'/hambox/signuptoken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: {
                        email: localStorage.getItem('email')
                    }
                })
            }).then(response => {
                return response.json()      
                .then( data => {
                    setToken(data.token);
                    localStorage.clear();
                })
            }).catch( () => {
                setToken('');
                localStorage.clear();
            })
        }
    });
    
    return ( 
            <div className="signup-confirmation">            
                
                <p>
                Thank You for subscribing to The Hammy Box! We will keep you informed with any news, photo's, videos and promotions.
                </p>
                
                <p>
                Please click the link below to download your free Hammy Box plans.
                </p>

                <a className="download-btn" onClick={ () => handleDownload() }>DOWNLOAD</a>
                                       
            </div>
    )
}

export default ThankYouPage;
