import React, {useEffect, useRef} from 'react';

const Gallery = () => {

    const useScroll = () => {
        const elRef = useRef(null);
        const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });     
        
        return [executeScroll, elRef];
      };

    const [scrollToTopOfPage, elRef] = useScroll();

    useEffect( () => {
        scrollToTopOfPage();
    });

    return (
            <div className="photo-gallery-content">

                <h1>Hamster Photo Gallery</h1>
                <hr width="80%" /><br/>

                <div ref={elRef} className="photo-container">

                    <a href="/gallery/photo_1.jpg" target="_blank"><img alt="Gallery Picture #1" src="/gallery/photo_1.jpg" /></a>
                    <a href="/gallery/photo_2.jpg" target="_blank"><img alt="Gallery Picture #2" src="/gallery/photo_2.jpg" /></a>
                    <a href="/gallery/photo_3.jpg" target="_blank"><img alt="Gallery Picture #3" src="/gallery/photo_3.jpg" /></a>

                    <a href="/gallery/photo_4.jpg" target="_blank"><img alt="Gallery Picture #4" src="/gallery/photo_4.jpg" /></a>
                    <a href="/gallery/photo_5.jpg" target="_blank"><img alt="Gallery Picture #5" src="/gallery/photo_5.jpg" /></a>
                    <a href="/gallery/photo_6.jpg" target="_blank"><img alt="Gallery Picture #6" src="/gallery/photo_6.jpg" /></a>

                    <a href="/gallery/photo_7.jpg" target="_blank"><img alt="Gallery Picture #7" src="/gallery/photo_7.jpg" /></a>
                    <a href="/gallery/photo_8.jpg" target="_blank"><img alt="Gallery Picture #8" src="/gallery/photo_8.jpg" /></a>
                    <a href="/gallery/photo_9.jpg" target="_blank"><img alt="Gallery Picture #9" src="/gallery/photo_9.jpg" /></a>

                </div>

            </div>
    )
}

export default Gallery;